import CommonHero, { HERO_IMAGE_NAME } from '../commons/CommonHero';

const FAQHeader = () => {
  return (
    <CommonHero
      title={'인프콘 FAQ'}
      description={'자주 묻는 질문을 모았어요'}
      heroImageName={HERO_IMAGE_NAME.faq}
    />
  );
};

export default FAQHeader;
