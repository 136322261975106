import FAQHeader from '../../components/infcon-2023/FAQ/FAQHeader';
import FAQBody from '../../components/infcon-2023/FAQ/FAQBody';
import { type HeadFC } from 'gatsby';
import Seo from '../../utils/Seo';
import { GATSBY_SITE_URL } from '../../utils/env';
import { URLS } from '../../utils/urls';

const FAQ = () => {
  return (
    <>
      <FAQHeader />
      <FAQBody />
    </>
  );
};

export const Head: HeadFC = () => {
  return (
    <Seo
      title="인프콘 2023 FAQ - INFCON 2023"
      description="자주 묻는 질문을 모았어요 - 인프런이 만드는 IT인의 축제, 인프콘으로 초대합니다. 인프콘과 함께 배우고 나누고 성장하세요. 다양한 발표 세션, 핸즈온, 네트워킹까지 만나보세요."
      fullUrl={`${GATSBY_SITE_URL}${URLS.INFCON_2023__FAQ}`}
    />
  );
};

export default FAQ;
