import { Box, Tabs, desktopFirstMediaQuery } from '@inflearn/ds-react';
import { useEffect, useState } from 'react';
import FAQAccordionList from './FAQAccordionList';
import { css } from '@emotion/react';

const FAQBody = () => {
  const [addAttend, setAddAttend] = useState(false);
  useEffect(() => {
    const currentTime = new Date();
    const addAttendTime = new Date('2023-08-15T18:00:00+09:00');

    if (currentTime >= addAttendTime) {
      setAddAttend(true);
    }
  }, []);
  return (
    <Box component="section" css={styleFAQBody}>
      <Box px={16} css={{ maxWidth: '1200px', width: '100%' }}>
        <Tabs variant="pills" radius="xl" defaultValue="application" color="blue">
          <Tabs.List mb={40}>
            <Tabs.Tab css={styleFAQTab} value="application">
              참가 신청
            </Tabs.Tab>
            <Tabs.Tab css={styleFAQTab} value="session">
              세션 및 행사 참여
            </Tabs.Tab>
            <Tabs.Tab css={styleFAQTab} value="etc">
              기타
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="application">
            <FAQAccordionList faqs={addAttend ? APPLICATION_FAQS : APPLICATION_FAQS.slice(0, APPLICATION_FAQS.length - 1)} />
          </Tabs.Panel>
          <Tabs.Panel value="session">
            <FAQAccordionList faqs={SESSION_FAQS} />
          </Tabs.Panel>
          <Tabs.Panel value="etc">
            <FAQAccordionList faqs={ETC_FAQS} />
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
};

const styleFAQBody = css({
  display: 'flex',
  justifyContent: 'center',
  padding: '40px 16px 80px',
  [desktopFirstMediaQuery.mobile]: {
    padding: '40px 0px 60px'
  }
});

const styleFAQTab = css({
  fontSize: '18px',
  fontWeight: 500,
  '&[aria-selected="true"]': {
    filter: 'brightness(1)',
    boxShadow:
      '0px 0.60px 0.60px -1.25px rgba(0, 0, 0, 0.36), 0px 2.28px 2.28px -2.5px rgba(0, 0, 0, 0.31779), 0px 10px 10px -3.75px rgba(0, 0, 0, 0.125)'
  },
  [desktopFirstMediaQuery.mobile]: {
    fontSize: '16px'
  }
});

const APPLICATION_FAQS = [
  {
    title: '참가 신청은 언제 어디서 어떻게 하나요?',
    description: (
      <>
        7월 10일(월) 13:00 부터 공식 홈페이지의 [참가 신청] 버튼을 통해 신청할 수 있습니다. 또는
        인프런 홈페이지의{' '}
        <a
          href="https://www.inflearn.com/course/%EC%9D%B8%ED%94%84%EC%BD%98-2023"
          target="_blank"
          rel="noreferrer">
          [인프콘 2023 참가 신청 페이지]
        </a>
        에서도 신청이 가능합니다.
      </>
    )
  },
  {
    title: '참가 신청은 유료인가요?',
    description:
      '티켓 구매는 유료이며, 추첨으로 참가자를 선정합니다. 추첨 선정자에 한해 참가권이 지급됩니다. 미선정자에게는 자동 환불이 진행됩니다.'
  },
  {
    title: '참가자 발표는 언제 어디서 하나요?',
    description:
      '7월 18일 화요일 13:00에 참가 신청 시 제출해 주신 이메일과 휴대폰 번호로 개별 안내 드릴 예정입니다.'
  },
  {
    title: '추첨 미선정자도 참여할 수 있나요?',
    description: '아쉽게도 추첨 미선정자에게는 참가권이 지급되지 않는 점 양해 부탁드립니다.'
  },
  {
    title: '당일 참가 등록도 가능한가요?',
    description: '사전 신청만 가능하며, 당일 신청 및 등록은 불가능합니다.'
  },
  {
    title: '인프콘 참가 신청 후, 취소 및 환불이 가능할까요?',
    description:
      '인프콘 2023 취소 및 환불 기간은 인프콘 2023 참가 신청 기간과 동일하며, 참가 신청 기간이 지난 이후의 취소 및 환불은 불가합니다. '
  },
  {
    title: '인프콘 티켓을 다른 사람에게 판매해도 되나요?',
    description:
      '인프콘 참가 티켓을 티켓 가격을 초과하여 중고나라 등 인터넷에서 되파는 것은 금지하고 있습니다. 적발시 이에 따른 불이익이 있을 수 있는 점 참고 부탁드립니다.'
  }
];

const ETC_FAQS = [
  {
    title: '점심 식사가 제공 되나요?',
    description:
      '점심 식사는 제공되지 않습니다. 행사장 내부에서 취식이 불가하니 가까운 식당에서 개별적으로 식사 부탁드립니다.'
  },
  {
    title: '행사장에서 Wifi와 충전기를 이용할 수 있나요?',
    description:
      '코엑스 공용 무료 와이파이를 이용할 수 있으며 충전 가능한 장소는 행사장에 마련할 예정입니다. '
  },
  {
    title: '주차 가능한가요?',
    description:
      '행사 참가자분들을 위한 별도의 주차 공간이 마련되어 있지 않아, 가급적 대중교통을 이용하시는 것을 권장 드립니다.'
  },
  {
    title: '인프콘 행사 당일 촬영 안내',
    description:
      '행사 당일 인프콘 2023 발표 세션, 부스 프로그램 등 행사장 내부에서 사진과 영상 촬영이 진행됩니다. 촬영된 사진과 영상은 마케팅 용도로 사용될 수 있는 점 미리 양해 부탁드립니다.'
  },
  {
    title: '행사의 세션별 발표 녹화 영상이 제공되나요?',
    description: '행사 이후, 인프런 홈페이지에 녹화 영상이 업로드 될 예정입니다.'
  }
];

const SESSION_FAQS = [
  {
    title: '당일 입장 시 준비해야 할 사항이 있나요?',
    description:
      '행사 당일 오전에 참가 등록 및 입장을 위한 QR 코드가 문자로 발송될 예정입니다. 행사장 등록 부스에서 수신하신 QR코드를 미리 준비해주시길 바랍니다. 해당 문자를 미수신한 경우 등록 부스의 안내 데스크에서 직접 신청 정보를 확인하신 후 입장이 가능하니 참고 부탁드립니다.'
  },
  {
    title: '듣고 싶은 발표 세션, 부스는 어떻게 참여하나요?',
    description:
      '공식 홈페이지의 타임 테이블을 참고하여 원하시는 세션과 부스에 자유롭게 참여해 주시면 됩니다. 발표 세션은 별도의 사전 신청 없이 자유롭게 들을 수 있습니다. 단, 핸즈온 세션은 미리 참가 신청을 받을 예정이니, 이점 참고 부탁드립니다.  '
  },
  {
    title: '당일 입장 시, 행사 시작 시간보다 늦어도 입장이 가능한가요?',
    description:
      '인프콘 등록 부스는 행사 1시간 전인, 오전 9시부터 오후 4시까지 (마지막 세션 전까지) 운영됩니다. 해당 시간 내에 도착하시면 입장이 가능합니다.'
  }
];

export default FAQBody;
